export const customerAccess = {
  id: 'customerAccess',
  name: _TL_('Customer access'),
  children: [{
    id: 'customersSelection',
    name: _TL_('Customers selection'),
    route: 'customers/selection',
    checkPermission: permissions => permissions.dynamic.WebpackifyPhase2Customer,
  }, {
    id: 'customersManagement',
    name: _TL_('Customers management'),
    route: 'customers/management',
    checkPermission: permissions => permissions.dynamic.WebpackifyPhase2Customer,
  }, {
    id: 'addCustomerAccess',
    name: _TL_('Add customer access'),
    route: 'customers/addcustomeraccess',
    checkPermission: permissions => permissions.dynamic.WebpackifyPhase2Customer,
  }, {
    id: 'createCustomer',
    name: _TL_('Create customer'),
    route: 'customers/createcustomer',
    checkPermission: permissions => permissions.dynamic.WebpackifyPhase2Customer,
  }],
};
