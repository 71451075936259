import { flatHotelCenterPages } from '../configs/tools/setup/hotel-center';
import { flatHotelCenterV2Pages } from '../configs/tools/setup/hotel-center-v2';
import {
  defaultScopes,
} from '../configs/shared';

export const hotel = {
  id: 'hotel-center',
  name: _TL_('Hotel Center'),
  description: _TL_('Manage ads and campaigns for your hotels and rental properties.'),
  scope: defaultScopes,
  children: [
    ...flatHotelCenterPages,
  ],
  checkPermission: permissions => permissions.dynamic.HotelAds && !permissions.dynamic.HotelCenterRemoval,
};

export const hotelV2 = {
  id: 'property-center',
  name: _TL_('Property Center'),
  description: _TL_('Manage ads and campaigns for your hotels and rental properties.'),
  scope: defaultScopes,
  children: [
    ...flatHotelCenterV2Pages,
  ],
  checkPermission: permissions => permissions.dynamic.HotelCenterV2,
};
