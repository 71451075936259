import { Scope } from '../shared';

export const adEditor = {
  id: 'ad-editor',
  name: _TL_('Create ad'),
  scope: [Scope.AdGroup],
  children: [
    {
      id: 'ad-editor',
      name: _TL_('Create ad'),
      route: 'ad',
      scope: [Scope.AdGroup],
      checkPermission: permissions => permissions.dynamic.LinkedInCampaign,
    },
  ],
};
