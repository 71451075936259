import React from 'react';
import PropTypes from 'prop-types';

export function ReactTemplate(props) {
  const {
    interpolate,
    model,
    template,
  } = props;

  function parseTemplate(templateStr) {
    try {
      let current = 0;
      const fragments = [];
      while (true) {
        const match = interpolate.exec(templateStr);

        if (match === null) break;

        const {
          0: nameWithBraces,
          1: name,
          index,
        } = match;

        const trimmedName = name.trim();

        const plainString = templateStr.substring(current, index);
        current = index + nameWithBraces.length;

        if (plainString) {
          fragments.push(plainString);
        }

        if (typeof model[trimmedName] === 'function') {
          const matchedPair = interpolate.exec(templateStr);
          if (matchedPair === null) {
            throw new SyntaxError(`Expected matching pair in form of {{/${trimmedName}}} but found none`);
          }
          const pairedNameWithBraces = matchedPair[0];
          const pairedName = matchedPair[1].trim();
          const pairedIndex = matchedPair.index;
          if (pairedName[0] !== '/' || pairedName.substring(1).trim() !== trimmedName) {
            throw new SyntaxError(`Expected matching pair in form of {{/${trimmedName}}} but found different match`);
          }
          const funcParamText = templateStr.substring(current, pairedIndex);
          fragments.push(model[trimmedName](funcParamText));
          current = pairedIndex + pairedNameWithBraces.length;
        } else {
          fragments.push(model[trimmedName]);
        }
      }

      const tail = templateStr.substring(current);
      if (tail) fragments.push(tail);

      return fragments;
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  const fragmentsArray = parseTemplate(template);

  if (!fragmentsArray.length) {
      return template || '';
  }

  return (
    <React.Fragment>
      {fragmentsArray.map((frag, index) => (
        <React.Fragment key={String(index)}>
          {frag}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}

ReactTemplate.propTypes = {
  interpolate: PropTypes.instanceOf(RegExp),
  model: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.func,
  ])).isRequired,
  template: PropTypes.string.isRequired,
};

ReactTemplate.defaultProps = {
  interpolate: /\{\{(.+?)\}\}/g,
};
