import { siteExclusionListsAccountLevelViewListsPage } from '../configs/tools/shared-library/site-exclusion-lists-account-level-view-lists';
import { siteExclusionListsCustomerLevelCreateListsPage } from '../configs/tools/shared-library/site-exclusion-lists-customer-level-create-lists';
import { contentExclusionAccountLevelPage } from '../configs/tools/shared-library/content-exclusion-account-level';
import { alwaysDisplayedItem, buildTree, Scope, defaultScopes } from '../configs/shared';

const brandSafetyControls = {
  id: 'brand-safety-controls',
  name: _TL_('Content suitability'),
  description: _TL_('Safeguard your brand image by blocking your ads from appearing with specific websites and types of content.'),
  scope: [Scope.Customer, ...defaultScopes],
  checkPermission: permissions => permissions.dynamic && (permissions.dynamic.BrandSafety || permissions.dynamic.BrandSafetyWithUnscored),
  children: [
    siteExclusionListsAccountLevelViewListsPage,
    siteExclusionListsCustomerLevelCreateListsPage,
    contentExclusionAccountLevelPage,
  ],
};

const tree = buildTree([brandSafetyControls], alwaysDisplayedItem, true, undefined, true)[0];

export { tree as brandSafetyControls };
