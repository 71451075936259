import { Scope } from '../shared';

export const XandrCustomerOnboarding = {
  id: 'XandrCustomerOnboardingStandalone',
  name: _TL_('Xandr Customer Onboarding'),
  children: [
    {
      id: 'XandrCustomerOnboarding',
      name: _TL_('Xandr Customer Onboarding'),
      route: 'Xandr/CustomerOnboarding',
      scope: [Scope.Account],
      checkPermission: permissions => permissions.dynamic.XandrCustomerOnboarding,
    },
  ],
};
