import { Scope } from '../../shared';

export const siteExclusionListCreate = {
  id: 'site-exclusion-lists-entrypoint',
  name: _TL_('Website exclusion lists'),
  scope: [Scope.Customer],
  route: 'siteexclusionlists/create',
  // CustomerHierarchy is needed for the multi-account-selector used in this page.
  checkPermission: permissions => permissions.dynamic && permissions.dynamic.ManagerAccountSharedWebsiteExclusions && !(permissions.dynamic.BrandSafety || permissions.dynamic.BrandSafetyWithUnscored),
  searchAlias: _TL_('Create website exclusion lists'),
};

export const siteExclusionListCreateBrandSafety = {
  id: 'site-exclusion-lists-entrypoint',
  name: _TL_('Content suitability'),
  scope: [Scope.Customer],
  route: 'siteexclusionlists/create',
  // CustomerHierarchy is needed for the multi-account-selector used in this page.
  checkPermission: permissions => permissions.dynamic && permissions.dynamic.ManagerAccountSharedWebsiteExclusions && (permissions.dynamic.BrandSafety || permissions.dynamic.BrandSafetyWithUnscored),
  searchAlias: _TL_('Create website exclusion lists'),
};

export const siteExclusionListsCustomerLevelCreateListsPage = {
  id: 'site-exclusion-lists-entrypoint',
  name: _TL_('Website exclusion lists'),
  // Enforce customer-level scope when routing to customer-level SiteExclusionLists pages
  scope: [Scope.Customer],
  children: [
    siteExclusionListCreate,
    siteExclusionListCreateBrandSafety,
    {
      id: 'site-exclusion-list-customer-edit',
      name: _TL_('Website exclusion lists'),
      scope: [Scope.Customer],
      route: 'siteexclusionlists/edit',
      // CustomerHierarchy is needed for the multi-account-selector used in this page.
      checkPermission: permissions => permissions.dynamic.ManagerAccountSharedWebsiteExclusions,
      searchable: false,
    },
  ],
};
