import { Scope } from '../../shared';

export const modeledConversions = {
  id: 'modeled-conversions',
  name: _TL_('Modeled conversions​'),
  oldUIScope: [Scope.Account],
  scope: [Scope.Account],
  children: [
    {
      id: 'modeled-conversions',
      name: _TL_('Conversion uplift from consent mode'),
      route: 'conversionupliftfromconsentmode',
      scope: [Scope.Account],
      checkPermission: permissions => permissions.dynamic.AllowModeledConversions,
    },
  ],
};
