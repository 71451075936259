import { Scope, alwaysDisplayed } from '../shared';

export const campaignCreate = {
  id: 'campaign-create',
  name: _TL_('New campaign'),
  route: 'createcampaign/objective',
  scope: [Scope.Account],
  campaignTypes: alwaysDisplayed,
  children: [
    {
      id: 'campaign-create-objective',
      name: _TL_('New campaign'),
      route: 'createcampaign/objective',
      campaignTypes: alwaysDisplayed,
      searchable: true,
    }, {
      id: 'campaign-create-search',
      name: _TL_('New campaign'),
      route: 'createcampaign/search',
      campaignTypes: alwaysDisplayed,
    }, {
      id: 'campaign-create-search-v2',
      name: _TL_('New campaign'),
      route: 'createcampaign/searchv2',
      campaignTypes: alwaysDisplayed,
      checkPermission: permissions => permissions.dynamic.SearchCampaignCreationRevamp,
    }, {
      id: 'campaign-create-audience',
      name: _TL_('New campaign'),
      route: 'createcampaign/audience',
      campaignTypes: alwaysDisplayed,
    }, {
      id: 'campaign-create-audience-simplified',
      name: _TL_('New campaign'),
      route: 'createcampaign/newaudience',
      campaignTypes: alwaysDisplayed,
      checkPermission: permissions => permissions.IsNewStaticAudienceCampaignCreationEnabled,
    }, {
      id: 'campaign-create-shopping',
      name: _TL_('New campaign'),
      route: 'createcampaign/searchshopping',
      campaignTypes: alwaysDisplayed,
    }, {
      id: 'campaign-create-shoppingaudience',
      name: _TL_('New campaign'),
      route: 'createcampaign/audienceshopping',
      campaignTypes: alwaysDisplayed,
    }, {
      id: 'campaign-create-videoaudience',
      name: _TL_('New campaign'),
      route: 'createcampaign/audiencevideo',
      campaignTypes: alwaysDisplayed,
      checkPermission: permissions => permissions.IsVideoAdsForCampaignEnabled && !permissions.dynamic.XandrVideoCampaigns,
    },
    {
      id: 'campaign-create-xandr-video',
      name: _TL_('New campaign'),
      route: 'createcampaign/awareness',
      campaignTypes: alwaysDisplayed,
      checkPermission: permissions => permissions.dynamic.XandrVideoCampaigns,
    },
    {
      id: 'campaign-create-xandr-display-ads',
      name: _TL_('New campaign'),
      route: 'createcampaign/displayads',
      campaignTypes: alwaysDisplayed,
      checkPermission: permissions => permissions.dynamic.XandrDisplayAdsCampaigns,
    },
    {
      id: 'campaign-create-xandr-mediapackage',
      name: _TL_('New campaign'),
      route: 'createcampaign/mediapackage',
      campaignTypes: alwaysDisplayed,
      checkPermission: permissions => permissions.IsDealEnabledForMsAds,
    },
    {
      id: 'campaign-create-xandr-mediadeal',
      name: _TL_('New campaign'),
      route: 'createcampaign/awarenessmediadeal',
      campaignTypes: alwaysDisplayed,
      checkPermission: permissions => permissions.IsDealEnabledForMsAds,
    },
    {
      id: 'campaign-create-display-plus',
      name: _TL_('New campaign'),
      route: 'createcampaign/displayplus',
      campaignTypes: alwaysDisplayed,
      checkPermission: permissions => permissions.dynamic.DisplayPlusCampaigns,
    },
    {
      id: 'campaign-create-app-ads',
      name: _TL_('New campaign'),
      route: 'createcampaign/appads',
      campaignTypes: alwaysDisplayed,
      checkPermission: permissions => permissions.dynamic.AppAdsCampaignPhaseOne,
    },
    {
      id: 'campaign-create-unified-app-ads',
      name: _TL_('New campaign'),
      route: 'createcampaign/unifiedappads',
      campaignTypes: alwaysDisplayed,
      checkPermission: permissions => permissions.dynamic.UnifiedAppAds,
    },
    {
      id: 'campaign-create-smart-shopping',
      name: _TL_('New Campaign'),
      route: 'createcampaign/smartshopping',
      campaignTypes: alwaysDisplayed,
      checkPermission: permissions =>
        ((permissions.dynamic.SmartShoppingCampaign || permissions.IsSmartShoppingCampaignEnabled) && !permissions.dynamic.SSCDeprecation),
    }, {
      id: 'campaign-create-hotel',
      name: _TL_('New campaign'),
      route: 'createcampaign/hotel',
      campaignTypes: alwaysDisplayed,
      checkPermission: permissions => (permissions.dynamic.HotelCampaign && !permissions.dynamic.IsRenameHotelToLodgingEnabled),
    }, {
      id: 'campaign-create-lodging',
      name: _TL_('New campaign'),
      route: 'createcampaign/lodging',
      campaignTypes: alwaysDisplayed,
      checkPermission: permissions => (permissions.dynamic.HotelCampaign && permissions.dynamic.IsRenameHotelToLodgingEnabled),
    }, {
      id: 'campaign-create-pmax',
      name: _TL_('New campaign'),
      route: 'createcampaign/performancemax',
      campaignTypes: alwaysDisplayed,
      checkPermission: permissions => permissions.dynamic.PerformanceMaxCampaigns,
    }, {
      id: 'campaign-create-pmax-v2',
      name: _TL_('New campaign'),
      route: 'createcampaign/performancemaxv2',
      campaignTypes: alwaysDisplayed,
      checkPermission: permissions => permissions.dynamic.PMaxWizardRefresh,
    }, {
      id: 'campaign-create-ms-store-ads',
      name: _TL_('New campaign'),
      route: 'createcampaign/msstoreads',
      campaignTypes: alwaysDisplayed,
      checkPermission: permissions => (!permissions.IsDomesticSimplifiedChineseEnabled && permissions.IsEnhancedShoppingCampaignAllowed) || (permissions.IsDomesticSimplifiedChineseEnabled && permissions.dynamic.WindowsStoreAdsCNExpansion),
    }, {
      id: 'campaign-create-shopping-spa',
      name: _TL_('New campaign'),
      route: 'createcampaign/mran',
      campaignTypes: alwaysDisplayed,
      checkPermission: permissions => permissions.IsSPAv2Enabled,
    }, {
      id: 'campaign-create-shopping-spb',
      name: _TL_('New campaign'),
      route: 'createcampaign/sponsoredpromotion',
      campaignTypes: alwaysDisplayed,
      checkPermission: permissions => permissions.IsSPAv2Enabled && permissions.dynamic.SponsoredPromotionsByBrands,
    },
    {
      id: 'campaign-create-linkedin',
      name: _TL_('New campaign'),
      route: 'createcampaign/linkedin',
      campaignTypes: alwaysDisplayed,
      checkPermission: permissions => permissions.dynamic.LinkedInCampaign,
    },
  ],
};
