import { defaultScopes } from '../../shared';

export const siteExclusionViewLists = {
  id: 'site-exclusion-lists-entrypoint',
  name: _TL_('Website exclusion lists'),
  scope: defaultScopes,
  route: 'siteexclusionlists/viewlists',
  checkPermission: permissions => permissions.dynamic && permissions.dynamic.ManagerAccountSharedWebsiteExclusions && !(permissions.dynamic.BrandSafety || permissions.dynamic.BrandSafetyWithUnscored),
  searchAlias: _TL_('View website exclusion lists'),
};

export const siteExclusionViewListsBrandSafety = {
  id: 'site-exclusion-lists-entrypoint',
  name: _TL_('Content suitability'),
  scope: defaultScopes,
  route: 'siteexclusionlists/viewlists',
  checkPermission: permissions => permissions.dynamic && permissions.dynamic.ManagerAccountSharedWebsiteExclusions && (permissions.dynamic.BrandSafety || permissions.dynamic.BrandSafetyWithUnscored),
  searchAlias: _TL_('View website exclusion lists'),
};

export const siteExclusionListsAccountLevelViewListsPage = {
  id: 'site-exclusion-lists-entrypoint',
  name: _TL_('Website exclusion lists'),
  // Allows entry to account-level SEL view lists page from tools menu unless if at customer scope
  scope: defaultScopes,
  children: [
    siteExclusionViewLists,
    siteExclusionViewListsBrandSafety,
    {
      id: 'site-exclusion-list-account-level-exclusions',
      name: _TL_('Website exclusion lists'),
      scope: defaultScopes,
      route: 'siteexclusionlists/viewexclusions',
      checkPermission: permissions => permissions.dynamic.ManagerAccountSharedWebsiteExclusions,
      searchable: false,
    },
  ],
};
