(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-jss"), require("hoist-non-react-statics"), require("@bingads-webui-react/hoc-utils"), require("underscore"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "react-jss", "hoist-non-react-statics", "@bingads-webui-react/hoc-utils", "underscore"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("react"), require("react-jss"), require("hoist-non-react-statics"), require("@bingads-webui-react/hoc-utils"), require("underscore")) : factory(root["react"], root["react-jss"], root["hoist-non-react-statics"], root["@bingads-webui-react/hoc-utils"], root["underscore"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__5__, __WEBPACK_EXTERNAL_MODULE__6__, __WEBPACK_EXTERNAL_MODULE__8__) {
return 