import { Scope } from '../shared';

export const signupPaymentSetup = {
  id: 'signupPaymentSetupStandalone',
  name: _TL_('Signup payment setup'),
  children: [{
    id: 'signupPaymentSetup',
    name: _TL_('Signup payment setup'),
    route: 'signup/paymentSetup',
    scope: [Scope.Account],
    checkPermission: permissions => permissions.dynamic.WebpackifyPhase2Customer,
  }],
};
