import { available, scope } from './common';

export const marketplaceInsights = {
  id: 'reports-v4-category-marketplaceinsights',
  name: _TL_('Marketplace Insights'),
  title: _TL_('Marketplace Insights'),
  fixedRoute: 'reports-v4/category/marketplaceinsights',
  route: 'reports-v4/category/marketplaceinsights',
  scope,
  available,
  checkPermission: /* istanbul ignore next */ permissions =>
    permissions.dynamic.DefaultCustomReport,
  children: [
    {
      id: 'reports-v4-searchverticalcategoryinsightsreport',
      name: _TL_('Category insights'),
      title: _TL_('To view the performance of industry categories on the Bing marketplace. This includes search and syndicated traffic.'),
      fixedRoute: 'reports-v4/create/searchverticalcategoryinsightsreport',
      route: 'reports-v4/create/searchverticalcategoryinsightsreport',
      searchAlias: _TL_('Search vertical category insights report'),
      scope,
      available,
      apiKey: 'SearchVerticalCategoryInsightsReport',
      description: _TL_('To view the performance of industry categories on the Bing marketplace. This includes search and syndicated traffic.'),
    },
    {
      id: 'reports-v4-searchverticalcategoryclicksharereport',
      name: _TL_('Category click coverage'),
      title: _TL_('To view the share of marketplace demand your account(s) have gained in a specific industry category.'),
      fixedRoute: 'reports-v4/create/searchverticalcategoryclicksharereport',
      route: 'reports-v4/create/searchverticalcategoryclicksharereport',
      searchAlias: _TL_('Category Click Coverage Report'),
      scope,
      available,
      apiKey: 'SearchVerticalCategoryClickShareReport',
      description: _TL_('To view the share of marketplace demand your account(s) have gained in a specific industry category.'),
    },
  ],
};
