/* istanbul ignore file */
import { CampaignType, Scope, AdGroupType } from '../shared';

export const assetsReport = {
  id: 'assets-report',
  name: _TL_('Assets'),
  campaignTypes: (type, permissions) => {
    if (type === CampaignType.Default) {
      return permissions.dynamic.RSAAssetCampaignAdgroupLevel;
    } else if (type === CampaignType.Audience) {
      return permissions.dynamic.AudienceAssetReport;
    } else if (type === CampaignType.All) {
      return true;
    }
    return false;
  },
  adGroupTypes: [AdGroupType.SearchStandard, AdGroupType.SearchDynamic],
  scope: [Scope.Account, Scope.Campaign, Scope.AdGroup],
  checkPermission: permissions => permissions.dynamic.RSAAssetCampaignAdgroupLevel || permissions.dynamic.AudienceAssetReport,
  children: [
    {
      id: 'assets-report',
      name: _TL_('Assets'),
      route: 'assets-report',
    },
  ],
};
