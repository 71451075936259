import { Scope } from '../shared';

export const accountsBulkUpdate = {
  id: 'accountsBulkUpdate',
  name: _TL_('Accounts bulk update'),
  children: [
    {
      id: 'accountsUpdateLocationsAndTaxes',
      name: _TL_('Bulk account update'),
      route: 'accounts/update',
      scope: [Scope.Customer],
      checkPermission: permissions => permissions.dynamic.WebpackifyPhase2Customer,
    },
    {
      id: 'accountsUpdatePrimaryContact',
      name: _TL_('Update primary contact'),
      route: 'accounts/UpdatePrimaryContact',
      scope: [Scope.Customer],
      checkPermission: permissions => permissions.dynamic.WebpackifyPhase2Customer,
    },
  ],
};
