import { Scope } from '../../shared';


export const assetsPage = {
  id: 'creative-studio-asset-library',
  name: _TL_('Assets'),
  route: 'assetlibrary',
  scope: [Scope.Account],
};

export const performancePage = {
  id: 'creative-studio-asset-library-performance',
  name: _TL_('Performance'),
  route: 'assetlibrary/performance',
  scope: [Scope.Account],
  apexViewName: 'assetlibrary',
  searchable: false,
  checkPermission: permissions => permissions.dynamic.AssetLibraryV4,
};

export const creativeStudioAssetLibraryPage = {
  id: 'creative-studio-asset-library',
  name: _TL_('Asset Library'),
  oldUIScope: [Scope.Account],
  scope: [Scope.Account],
  route: 'assetlibrary',
  children: [
    // images
    assetsPage,
    {
      id: 'creative-studio-asset-library-image-add-folder',
      name: _TL_('Add images folder'),
      route: 'assetlibrary/image/addfolder',
      scope: [Scope.Account],
      apexViewName: 'assetlibrary',
      searchable: false,
      checkPermission: permissions => !(permissions.dynamic.AssetLibraryV3EnableVideoUpload),
    },
    {
      id: 'creative-studio-asset-library-image-add',
      name: _TL_('Add images'),
      route: 'assetlibrary/image/add',
      scope: [Scope.Account],
      apexViewName: 'assetlibrary',
      searchable: false,
    },
    {
      id: 'creative-studio-asset-library-image-aigc',
      name: _TL_('AIGC'),
      route: 'assetlibrary/image/aigenerate',
      scope: [Scope.Account],
      apexViewName: 'assetlibrary',
      searchable: false,
      checkPermission: permissions => permissions.dynamic.AssetLibraryAIGCMVP,
    },
    {
      id: 'creative-studio-asset-library-image-details',
      name: _TL_('Asset library images'),
      route: 'assetlibrary/image/:imageId',
      scope: [Scope.Account],
      apexViewName: 'assetlibrary',
      searchable: false,
    },
    {
      id: 'creative-studio-asset-library-image-edit',
      name: _TL_('Edit image'),
      route: 'assetlibrary/image/:imageId/edit',
      scope: [Scope.Account],
      apexViewName: 'assetlibrary',
      searchable: false,
    },
    // videos
    {
      id: 'creative-studio-asset-library-video-add',
      name: _TL_('Add videos'),
      route: 'assetlibrary/video/add',
      scope: [Scope.Account],
      apexViewName: 'assetlibrary',
      checkPermission: permissions => permissions.dynamic.AssetLibraryV3EnableVideoUpload,
      searchable: false,
    },
    {
      id: 'creative-studio-asset-library-video-details',
      name: _TL_('Asset library videos'),
      route: 'assetlibrary/video/:videoId',
      scope: [Scope.Account],
      apexViewName: 'assetlibrary',
      searchable: false,
    },
    // mix assets
    {
      id: 'creative-studio-asset-library-add-folder',
      name: _TL_('Add assets folder'),
      route: 'assetlibrary/addfolder',
      scope: [Scope.Account],
      apexViewName: 'assetlibrary',
      searchable: false,
      checkPermission: permissions => permissions.dynamic.AssetLibraryV3EnableVideoUpload,
    },
    {
      id: 'creative-studio-asset-library-add',
      name: _TL_('Add assets'),
      route: 'assetlibrary/add',
      scope: [Scope.Account],
      apexViewName: 'assetlibrary',
      searchable: false,
      checkPermission: permissions => permissions.dynamic.AssetLibraryV3EnableVideoUpload,
    },
    // performance
    performancePage,
    // performance -> image deails
    {
      id: 'creative-studio-asset-library-performance-image-details',
      name: _TL_('Performance'),
      route: 'assetlibrary/performance/image/:imageId',
      scope: [Scope.Account],
      apexViewName: 'assetlibrary',
      searchable: false,
      checkPermission: permissions => permissions.dynamic.AssetLibraryV4,
    },
    // performance -> edit image
    {
      id: 'creative-studio-asset-library-performance-image-edit',
      name: _TL_('Edit image'),
      route: 'assetlibrary/performance/image/:imageId/edit',
      scope: [Scope.Account],
      apexViewName: 'assetlibrary',
      searchable: false,
      checkPermission: permissions => permissions.dynamic.AssetLibraryV4,
    },
    // task progress
    {
      id: 'creative-studio-asset-library-task-progress',
      name: _TL_('Asset library'),
      route: 'assetlibrary/taskprogress',
      scope: [Scope.Account],
      apexViewName: 'assetlibrary',
      searchable: false,
    },
  ],
  checkPermission: permissions => !permissions.dynamic.AdsStudioMVP,
};
