import { defaultScopes } from '../../shared';

const contentExclusion = {
  id: 'content-exclusion-entrypoint',
  name: _TL_('Content suitability'),
  scope: defaultScopes,
  route: 'contentexclusion/brandsafety',
  checkPermission: permissions => permissions.dynamic && (permissions.dynamic.BrandSafety || permissions.dynamic.BrandSafetyWithUnscored),
  searchAlias: _TL_('content exclusion'),
};

export const contentExclusionAccountLevelPage = {
  id: 'content-exclusion-entrypoint',
  name: _TL_('Content exclusions'),
  scope: defaultScopes,
  checkPermission: permissions => permissions.dynamic && (permissions.dynamic.BrandSafety || permissions.dynamic.BrandSafetyWithUnscored),
  children: [
    contentExclusion,
  ],
};
